import * as React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  useScrollTrigger,
} from '@mui/material';
import { Menu as MenuIcon, Person as PersonIcon, ExitToApp as LogoutIcon } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import { logoutAction } from '../../features/auth/authActions';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import logo from '../../media/company-logo.png';

const NAVBAR_HEIGHT = 50;
const MOBILE_NAVBAR_HEIGHT = 44;

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, refresh } = useSelector((state) => state.auth);
  const isAuthenticated = !!token && !!refresh;
  const { t } = useTranslation();

  const pages = [
    { title: t('common.pricing'), path: '/pricing' },
    { title: t('common.blog'), path: '/blog' },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    dispatch(logoutAction({ refreshToken: refresh }));
    dispatch(logout());
    navigate('/');
  };

  return (
    <ElevationScroll>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: '#191a23',
          boxShadow: 'none',
        }}
      >
        <Container maxWidth='xl'>
          <Toolbar
            disableGutters
            sx={{
              minHeight: { xs: MOBILE_NAVBAR_HEIGHT, md: NAVBAR_HEIGHT },
              py: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Logo */}
            <Box
              component={Link}
              to='/'
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                mr: 3,
              }}
            >
              <img
                src={logo}
                alt='Eronware Logo'
                style={{
                  width: '150px',
                  height: '40px',
                  objectFit: 'contain',
                }}
              />

              {/* Desktop Navigation */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
                {pages.map((page) => (
                  <Button
                    key={page.title}
                    component={Link}
                    to={page.path}
                    sx={{
                      color: 'white',
                      fontSize: '1rem',
                      textTransform: 'none',
                      px: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                      mb: -1,
                    }}
                  >
                    {page.title}
                  </Button>
                ))}
              </Box>
            </Box>


            {/* Mobile Menu */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >

              <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }}>
                <LanguageSwitcher />
              </MenuItem>

              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size='large'
                  aria-label='menu'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleOpenNavMenu}
                  sx={{ color: 'white' }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiPaper-root': {
                      backgroundColor: '#191a23',
                      color: 'white',
                      minWidth: 200,
                    },
                  }}
                >
                  {/* Navigation Links */}
                  {pages.map((page) => (
                    <MenuItem
                      key={page.title}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={page.path}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                      }}
                    >
                      <Typography textAlign='center'>{page.title}</Typography>
                    </MenuItem>
                  ))}

                  {/* Divider */}
                  <Box sx={{ my: 1, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }} />

                  {/* Auth Buttons in Mobile Menu */}
                  {isAuthenticated ? (
                    <>
                      <MenuItem
                        onClick={handleLogout}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                        }}
                      >
                        <LogoutIcon sx={{ mr: 1.5, fontSize: 20 }} />
                        <Typography textAlign='center'>{t('common.logout')}</Typography>
                      </MenuItem>

                    </>
                  ) : (
                    <>
                      <MenuItem
                        component={Link}
                        to='/login'
                        onClick={handleCloseNavMenu}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                        }}
                      >
                        <Typography textAlign='center'>{t('common.login')}</Typography>
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </Box>
            </Box>

            {/* Hide language switcher and auth buttons on mobile */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 2 }}>

              {/* Language Switcher in Mobile Menu */}
              <MenuItem sx={{ display: { xs: 'flex' } }}>
                <LanguageSwitcher />
              </MenuItem>

              {/* Auth Buttons or User Menu */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {isAuthenticated ? (
                  <>
                    <Tooltip title={t('common.account')}>
                      <IconButton onClick={handleOpenUserMenu}>
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            bgcolor: 'primary.main',
                            transition: 'transform 0.2s',
                            '&:hover': {
                              transform: 'scale(1.1)',
                            },
                          }}
                        >
                          <PersonIcon />
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{
                        mt: '45px',
                        '& .MuiPaper-root': {
                          backgroundColor: '#191a23',
                          color: 'white',
                          minWidth: 180,
                        },
                      }}
                      id='menu-appbar'
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem
                        onClick={handleLogout}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                        }}
                      >
                        <LogoutIcon sx={{ mr: 1.5, fontSize: 20 }} />
                        <Typography textAlign='center' color='white'>
                          {t('common.logout')}
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      component={Link}
                      to='/login'
                      variant='outlined'
                      sx={{
                        color: 'white',
                        borderColor: 'white',
                        '&:hover': {
                          borderColor: 'white',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                      }}
                    >
                      {t('common.login')}
                    </Button>
                    <Button
                      component={Link}
                      to='/register'
                      variant='contained'
                      sx={{
                        bgcolor: 'primary.main',
                        '&:hover': {
                          bgcolor: 'primary.dark',
                        },
                      }}
                    >
                      {t('common.signup')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
}

export default NavBar;
